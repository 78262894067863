import doRequest from '../../api';
import { APP_CONSTANTS } from '../../constants';
import {
  APPROVE_GENERATED_BILL,
  DOWNLOAD_BILL_CSV,
  DOWNLOAD_BILL_PDF,
  DOWNLOAD_GROUP_BILL_REPORT,
  GET_AGENCY_BILLING_EMAILS_BY_AGENCY_ID,
  GET_AGENCY_BILLS,
  GET_GROUP_BILLS,
  GET_PERIODS_API,
  REJECT_GENERATED_BILL,
  UPDATE_AGENCY_BILLING_EMAILS_BY_AGENCY_ID,
} from './constants';

export const getPeriods = () => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/periods`,
    actionType: GET_PERIODS_API,
  };
  return dispatch(doRequest.get(config));
};

export const getGroupBills = (periodId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/group/${periodId}`,
    actionType: GET_GROUP_BILLS,
  };
  return dispatch(doRequest.get(config));
};

export const getAgencyBills = (periodId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/agency/${periodId}`,
    actionType: GET_AGENCY_BILLS,
  };
  return dispatch(doRequest.get(config));
};

export const downloadBillPdf = (url) => (dispatch) => {
  const config = {
    url: '',
    headers: {
      'Content-Type': 'application/pdf',
    },
    responseType: 'blob',
    baseURL: `${url}`,
    actionType: DOWNLOAD_BILL_PDF,
  };
  return dispatch(doRequest.get(config));
};

export const downloadBillCsvReport = (url) => (dispatch) => {
  const config = {
    url: '',
    baseURL: `${url}`,
    headers: {
      'Content-Type': 'text/csv',
    },
    actionType: DOWNLOAD_BILL_CSV,
    responseType: 'blob',
  };
  return dispatch(doRequest.get(config));
};

export const approveBill = (billId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/${billId}/approve`,
    actionType: APPROVE_GENERATED_BILL,
  };
  return dispatch(doRequest.put(config));
};

export const rejectBill = (billId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/${billId}/reject`,
    actionType: REJECT_GENERATED_BILL,
  };
  return dispatch(doRequest.put(config));
};

export const getAgencyBillingEmails = (agencyId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.AGENCY}/${agencyId}/billing`,
    actionType: GET_AGENCY_BILLING_EMAILS_BY_AGENCY_ID,
  };
  return dispatch(doRequest.get(config));
};

export const updateAgencyBillingEmails = (billingData) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLING}/email`,
    actionType: UPDATE_AGENCY_BILLING_EMAILS_BY_AGENCY_ID,
    formData: true,
    data: billingData,
  };
  return dispatch(doRequest.put(config));
};

export const getGroupBillReport = (groupId, periodId) => (dispatch) => {
  const config = {
    url: `${APP_CONSTANTS.API_LINKS.BILLS}/groupbillsreport/${periodId}/group/${groupId}`,
    actionType: DOWNLOAD_GROUP_BILL_REPORT,
    headers: {
      Accept: 'text/csv',
      'Content-Type': 'application/json',
    },
  };
  return dispatch(doRequest.get(config));
};
