import { createAPIActions } from '../../utils';

export const BILLS_INITIAL_STATE = {
  billingList: [],
  isFetching: false,
};

export const GET_PERIODS_API = createAPIActions('get', 'periods/api');
export const GET_GROUP_BILLS = createAPIActions('get', 'groups/bills/api');
export const GET_AGENCY_BILLS = createAPIActions('get', 'agency/bills/api');
export const DOWNLOAD_BILL_PDF = createAPIActions(
  'get',
  'billing/download/pdf',
);
export const DOWNLOAD_BILL_CSV = createAPIActions(
  'get',
  'billing/download/csv',
);
export const APPROVE_GENERATED_BILL = createAPIActions(
  'update',
  'bills/approve/api',
);
export const REJECT_GENERATED_BILL = createAPIActions(
  'update',
  'bills/reject/api',
);

export const GET_AGENCY_BILLING_EMAILS_BY_AGENCY_ID = createAPIActions(
  'get',
  'agency/billing/emails/api',
);

export const UPDATE_AGENCY_BILLING_EMAILS_BY_AGENCY_ID = createAPIActions(
  'update',
  'update/agency/billing/emails',
);

export const DOWNLOAD_GROUP_BILL_REPORT = createAPIActions(
  'get',
  'billing/groupBillReport/download/pdf',
);
